import { Button } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import React, { FC, ReactElement, useMemo } from 'react';
import { FunctionField, useMutation, useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import AppCard from '../../Components/ui/card/AppCard';
import AppBaseForm from '../../Components/ui/form/AppBaseForm';
import { AppDateTimeInput, AppSelectInput, AppTextInput } from '../../Components/ui/input';
import { dateFormatter } from '../../utils/data-formatters';
import { COMPLAINT_STATUS_CHOICES, COMPLAINT_TYPE_CHOICES } from './constants';
import { ComplaintStatus } from './enums';

const ComplaintDetails: FC<any> = (props): ReactElement => {
  const notify = useNotify();
  const { record } = props;

  // Filtro le opzioni di stato disponibili in base allo stato attuale
  const statusChoices = useMemo(() => {
    // Se lo stato attuale è "unsettled", rimuovo l'opzione "open"
    if (record?.status === ComplaintStatus.Unsettled) {
      return COMPLAINT_STATUS_CHOICES.filter(choice => choice.id !== ComplaintStatus.Open);
    }
    // Altrimenti mostro tutte le opzioni
    return COMPLAINT_STATUS_CHOICES;
  }, [record?.status]);

  const [sendMessage, { loading }] = useMutation();
  const onSubmit = (data) => {
    sendMessage(
      {
        type: 'create',
        resource: 'complaints-messages',
        payload: {
          data: {
            fkComplaint: Number(data.fkComplaint),
            text: data.text,
            type: data.type,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify('Messaggio inviato con successo.', 'info');
          window.location.reload();
          // TODO: RESET FORM
          // TODO: UPDATE MESSAGES
        },
        onFailure: (err) => {
          console.error('Error sending message:', err);
          notify(
            `Errore durante l'invio del messaggio: ${err?.message ?? 'ERRORE_SCONOSCIUTO'}`,
            'error',
          );
        },
      },
    );
  };

  return (
    <AppBaseForm {...props} disabledCards={props.mode === 'show'}>
      <AppCard expanded title="Controversia">
        <AppTextInput source="id" label="Identificativo univoco" disabled md={3} />
        <AppDateTimeInput source="createdAt" label="Data/ora apertura" disabled md={3} />
        <AppDateTimeInput source="updatedAt" label="Data/ora ultimo update" disabled md={3} />
        <AppDateTimeInput source="closedDate" label="Data/ora chiusura" disabled md={3} />
        <AppSelectInput
          source="type"
          choices={COMPLAINT_TYPE_CHOICES}
          label="Tipo"
          required
          md={6}
        />
        <AppSelectInput
          source="status"
          choices={statusChoices}
          label="Stato"
          required
          md={6}
        />
        <FunctionField
          label="Messaggi"
          render={(record) => {
            return (
              <>
                {record?.messages
                  ?.sort((m1, m2) => m1.id > m2.id)
                  .map((message) => {
                    let fromString = '';
                    switch (message.type) {
                      case 'participant':
                        fromString = 'Acquirente';
                        break;
                      case 'seller':
                        fromString = 'Venditore';
                        break;
                      case 'cda':
                        fromString = 'Assistenza Casa Delle Aste';
                        break;
                      default:
                        fromString = 'Sconosciuto';
                        break;
                    }
                    return (
                      <div
                        style={{
                          textAlign: message.type === 'participant' ? 'left' : 'right',
                          marginBottom: '20px',
                          border: message.type === 'cda' ? '1px solid #f50057' : 'none',
                        }}
                      >
                        <div style={{ fontSize: '12px' }}>
                          {fromString} - {dateFormatter(message.date, false)}
                        </div>
                        <div>{message.text}</div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: message.type === 'participant' ? 'start' : 'end',
                            rowGap: '10px',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          {message.pictures?.map((picture) => (
                            <a href={picture.url} target="_blank" rel="noreferrer">
                              <a href={picture.url} key={picture.id} target="_blank">
                                <img
                                  src={picture.url}
                                  style={{
                                    width: '128px',
                                    height: '128px',
                                    objectFit: 'cover',
                                  }}
                                />
                              </a>
                            </a>
                          ))}
                        </div>
                      </div>
                    );
                  })}
              </>
            );
          }}
        />
      </AppCard>
      <AppCard expanded title="Nuovo messaggio">
        <Form
          onSubmit={(data) => {
            const type = 'seller';
            const fkComplaint = props.id;
            onSubmit({ ...data, type, fkComplaint });
          }}
          render={(formProps) => {
            const { handleSubmit, submitting, pristine, hasValidationErrors /*errors*/ } =
              formProps;
            const disabled = submitting || pristine || loading;

            const checkValidationErrors = () =>
              hasValidationErrors && notify('ra.message.invalid_form', 'error');

            return (
              <React.Fragment>
                <AppTextInput source="text" label="Testo" required multiline md={12} />
                <Button
                  disabled={disabled}
                  onClick={() => {
                    checkValidationErrors();
                    handleSubmit();
                  }}
                  variant="contained"
                  color="secondary"
                  autoFocus
                  startIcon={<Save />}
                  children="Invia messaggio"
                />
              </React.Fragment>
            );
          }}
        />
      </AppCard>
    </AppBaseForm>
  );
};

export default ComplaintDetails;
