export enum ComplaintType {
  NotReceived = 'not-received',
  Other = 'other',
}

export enum ComplaintStatus {
  Open = 'open',
  Unsettled = 'unsettled',
  Closed = 'closed',
}
