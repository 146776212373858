import React, { FunctionComponent, ReactElement, ReactNode, useEffect } from 'react';
import { FilterProps, useListContext } from 'react-admin';
import AppListFilterForm from '../form/AppListFilterForm';

type AppListFilterProps = Omit<FilterProps, 'children'> & {
  children?: ReactNode;
  helperText?: string;
  initialFilters?: Record<string, any>;
  filterButtons?: ReactElement[];
  disableFulltextSearch?: boolean;
  defaultParams?: Record<string, any>;
};

const AppListFilter: FunctionComponent<AppListFilterProps> = (props) => {
  const { showFilter } = useListContext();

  const { children, helperText, filterButtons, initialFilters, disableFulltextSearch } = props;
  useEffect(() => {
    showFilter('q', '');
  }, []);

  const filterList: string[] = [];
  React.Children.forEach<ReactNode>(children, (child: any) => {
    if (child && child.props && child.props.source) {
      filterList.push(child.props.source);
    }
  });

  return props.context === 'button' ? (
    <React.Fragment /> //<AppListFilterButton filtersList={filterList} initialFilters={initialFilters} />
  ) : (
    <AppListFilterForm
      filtersList={filterList}
      initialFilters={initialFilters}
      helperText={helperText}
      buttons={filterButtons}
      disableFulltextSearch={disableFulltextSearch}
      defaultParams={props.defaultParams}
    >
      {filterList.length > 0 ? children : <span />}
    </AppListFilterForm>
  );
};

export default AppListFilter;
