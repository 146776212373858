import { makeStyles } from '@material-ui/core';
import {
  Check as AuthorizedIcon,
  Dehaze as PendingIcon,
  Clear as UnauthorizedIcon,
} from '@material-ui/icons';
import { FC, memo } from 'react';
import { FunctionField, ListProps, ReferenceField, SelectField, usePermissions } from 'react-admin';
import { UpdatePaymentStateButton } from '../../Components/payment/update-payment-state-button';
import AppEditButton from '../../Components/ui/button/AppEditButton';
import AppDatagrid from '../../Components/ui/datagrid/AppDatagrid';
import AppList from '../../Components/ui/detail/AppList';
import { AppTextField } from '../../Components/ui/field';
import AppLinkToEntityField from '../../Components/ui/field/AppLinkToEntityField';
import { AppNumberInput, AppSelectInput } from '../../Components/ui/input';
import AppListFilter from '../../Components/ui/layout/AppListFilter';
import AppTitle from '../../Components/ui/layout/AppTitle';
import AppTooltip from '../../Components/ui/tooltip/AppTooltip';
import { ResourceAction } from '../../Providers/AppPermissionsProvider';
import { useUserIsAllowed } from '../../hooks/use-user-is-allowed';
import { User } from '../../types/user.type';
import { FolderType } from '../Folder/enums';
import { PaymentExpand } from './PaymentExpand';
import { PAYMENT_METHODS, PAYMENT_REASONS, PAYMENT_TYPES, PaymentType } from './constants';
import { blue, green, grey, red, yellow } from '@material-ui/core/colors';
import AuthManager from '../../Providers/AuthManager';

const useStyles = makeStyles(
  (theme) => ({
    // --- Datagrid columns ---
    paymentType: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    paymentReason: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(20),
    },
    paymentMethod: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(30),
    },
    participation: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(40),
    },
    saleExperiment: {
      whiteSpace: 'nowrap',
    },
    codes: {
      whiteSpace: 'nowrap',
    },
    procedureTitle: {
      whiteSpace: 'nowrap',
      minWidth: theme.spacing(40),
    },
    verified: {
      whiteSpace: 'nowrap',
      width: '100%',
    },
    lastCol: {
      width: '100%',
    },

    // --- Payment status ---
    pendingPayment: {
      color: theme.palette.warning.main,
    },
    paymentVerified: {
      color: theme.palette.success.main,
    },
    paymentNotVerified: {
      color: theme.palette.error.main,
    },
  }),
  { name: 'PaymentList' },
);

const IS_IVG = process.env.REACT_APP_IS_IVG === 'true';
const userJson = AuthManager.getUserInfo();
const user: User = userJson ? JSON.parse(userJson) : {};
const isAgency = user.agency ? true : false;

const PaymentListFilters = (
  <AppListFilter
    defaultParams={{ fromAdmin: true }}
    helperText="Anagrafica, codici del lotto, codici della procedura"
  >
    <AppSelectInput
      source="paymentType"
      choices={PAYMENT_TYPES}
      label="Tipo di pagamento"
      md={2}
      resettable
    />
    <AppSelectInput
      source="paymentReason"
      choices={PAYMENT_REASONS}
      label="Causale"
      md={2}
      resettable
    />
    <AppSelectInput
      source="paymentState"
      choices={[
        { id: 'verified', name: 'Verificato' },
        { id: 'not-valid', name: 'Non valido' },
        { id: 'pending', name: 'In attesa di verifica' },
      ]}
      label="Stato pagamento"
      md={2}
      resettable
    />
    <AppNumberInput source="saleExperimentId" label="ID esperimento di vendita" md={2} />
  </AppListFilter>
);

const PaymentList: FC<ListProps> = memo<ListProps>((props) => {
  const classes = useStyles();

  const allowedToSeeParticipants = useUserIsAllowed(ResourceAction.Get, 'participants');

  const { permissions: user } = usePermissions<User>();

  return (
    <AppList
      {...props}
      title={<AppTitle title="Pagamenti" />}
      sort={{
        field: 'createdAt',
        order: 'DESC',
      }}
      filters={PaymentListFilters}
      /*
       * TODO:
       *  - integrate CSV invoice generation action (AS-1694)
       * */
    >
      <AppDatagrid
        expand={PaymentExpand}
        rowStyle={(record) => {
          if (IS_IVG || isAgency) {
            return;
          }
          const hasOpenComplaints = record?.hasOpenComplaints ?? false;
          const hasUnsettledComplaints = record?.hasUnsettledComplaints ?? false;
          const alreadyPayed = record?.payedToSeller ?? false;
          if(alreadyPayed) {
            return {backgroundColor: green[200]}
          }
          const today = new Date(); // Get today's date

          // Add 30 days to paymentCapture or auction end
          const gracePeriodEnd = new Date(record?.capturedAt ?? record?.auctionClosedAt);
          gracePeriodEnd.setDate(gracePeriodEnd.getDate() + 30);

          // Compare
          const isPayable = today >= gracePeriodEnd && record?.verified;

          if (hasUnsettledComplaints) {
            return { backgroundColor: red[200] };
          }

          return isPayable
            ? hasOpenComplaints
              ? { backgroundColor: yellow[200] }
              : { backgroundColor: blue[200] }
            : { backgroundColor: grey[200] };
        }}
      >
        <SelectField
          choices={PAYMENT_TYPES}
          source="paymentType"
          label="Tipo di pagamento"
          headerClassName={classes.paymentType}
        />
        <SelectField
          choices={PAYMENT_REASONS}
          source="paymentReason"
          label="Causale"
          headerClassName={classes.paymentReason}
        />
        <SelectField
          choices={PAYMENT_METHODS}
          source="paymentMethod"
          label="Modalità di pagamento"
          headerClassName={classes.paymentMethod}
        />
        <FunctionField
          label="Anagrafica"
          render={(payment?: Record<string, any>) => {
            if (!payment?.participation) return <></>;

            const text = `${payment?.participation.presenter.lastName} ${
              payment.participation.presenter.firstName
            } (${
              payment.participation.presenter.taxCode ?? payment.participation.presenter.vat ?? '-'
            })`;
            return allowedToSeeParticipants ? (
              <AppLinkToEntityField
                mode="show"
                targetResource="participants"
                targetResourceId={payment.participation.fkPresenter}
                originName="pagamenti"
                value={text}
              />
            ) : (
              <AppTextField customText={text} />
            );
          }}
          headerClassName={classes.participation}
        />

        <ReferenceField
          reference={
            IS_IVG
              ? 'sale-experiments-judiciary'
              : user?.agency
              ? 'sale-experiments-private'
              : 'sale-experiments'
          }
          source="fkSaleExperiment"
          label="Esperimento di vendita"
          link={false}
          headerClassName={classes.saleExperiment}
        >
          <FunctionField
            render={(saleExp) =>
              saleExp ? (
                <AppLinkToEntityField
                  mode="show"
                  targetResource="sale-experiments"
                  targetResourceId={saleExp.id as number}
                  originName="pagamenti"
                  value={saleExp.id as string}
                />
              ) : (
                <></>
              )
            }
          />
        </ReferenceField>
        {IS_IVG && (
          <AppTextField source="ivgCode" label="Codice IVG" headerClassName={classes.codes} />
        )}
        <AppTextField source="code" label="Codice lotto" headerClassName={classes.codes} />
        <FunctionField
          label="Procedura"
          render={(payment) =>
            payment ? (
              payment.folderType === FolderType.Judiciary ? (
                <AppTextField customText={payment.procedureCode} />
              ) : (
                <AppTextField customText={payment.folderTitle} truncate />
              )
            ) : (
              <></>
            )
          }
          headerClassName={classes.procedureTitle}
        />
        <FunctionField
          label="Stato pagamento"
          render={(payment) =>
            payment ? (
              <AppTooltip
                title={
                  payment.pending
                    ? 'In attesa di verifica'
                    : payment.verified
                    ? 'Verificato'
                    : 'Non valido'
                }
              >
                {payment.pending ? (
                  <PendingIcon className={classes.pendingPayment} />
                ) : payment.verified ? (
                  <AuthorizedIcon className={classes.paymentVerified} />
                ) : (
                  <UnauthorizedIcon className={classes.paymentNotVerified} />
                )}
              </AppTooltip>
            ) : (
              <></>
            )
          }
          headerClassName={classes.verified}
        />
        {!IS_IVG && !isAgency && (
          <FunctionField
            label="Pagato al venditore"
            render={(payment) =>
              payment ? (
                <AppTooltip title={payment.payedToSeller ? 'Pagato' : 'Non ancora pagato'}>
                  {payment.payedToSeller ? (
                    <AuthorizedIcon className={classes.paymentVerified} />
                  ) : (
                    <UnauthorizedIcon className={classes.paymentNotVerified} />
                  )}
                </AppTooltip>
              ) : (
                <></>
              )
            }
            headerClassName={classes.verified}
          />
        )}
        <FunctionField
          render={(payment) =>
            payment?.paymentType === PaymentType.Sent ? (
              <UpdatePaymentStateButton record={payment} />
            ) : (
              <AppEditButton record={payment} size="medium" fullWidth />
            )
          }
        />
      </AppDatagrid>
    </AppList>
  );
});

export default PaymentList;
