import { Button, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { FC, Fragment } from 'react';
import { useMutation, useNotify, useRefresh, usePermissions } from 'react-admin';
import { useToggleState } from '../../hooks/use-toggle-state';
import { useUserIsAllowed } from '../../hooks/use-user-is-allowed';
import { ResourceAction } from '../../Providers/AppPermissionsProvider';
import AppFormDialog from '../ui/dialog/form-dialog/form-dialog';
import { AppSelectInput, AppTextInput } from '../ui/input';
import { User } from '../../types/user.type';

const useStyles = makeStyles(
  () => ({
    button: {
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'VerificationButton' },
);

export const UpdatePaymentStateButton: FC<any> = React.memo(({ record }) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const hasOpenComplaints = record?.hasOpenComplaints ?? false;
  const hasUnsettledComplaints = record?.hasUnsettledComplaints ?? false;
  const today = new Date(); // Get today's date
  const { permissions: user } = usePermissions<User>();
  const isCdaAdmin = user?.admin === true;

  // Add 30 days to paymentCapture or auction end
  const gracePeriodEnd = new Date(record?.capturedAt ?? record?.auctionClosedAt);
  gracePeriodEnd.setDate(gracePeriodEnd.getDate() + 30);
  const isPayable =
    today >= gracePeriodEnd && record?.verified && !hasOpenComplaints && !hasUnsettledComplaints;
  const [verificationDialogOpen, verificationDialogToggle] = useToggleState();

  const allowedToUpdate = useUserIsAllowed(ResourceAction.Put, 'payments');

  const [mutatePaymentStatus] = useMutation();
  const onPaymentStatusUpdateSubmit = (data) =>
    mutatePaymentStatus(
      {
        type: 'update',
        resource: `payments/${record.id}/update-payment-status`,
        payload: {
          data,
        },
      },
      {
        onSuccess: () => {
          notify('Operazione conclusa con successo.');
          refresh();
          verificationDialogToggle();
        },
        onFailure: (err) => {
          console.error('Error updating payment state:', err);
          notify(`Operazione non riuscita: ${err?.message ?? 'ERRORE_SCONOSCIUTO'}`, 'error');
        },
      },
    );

  return (
    <Fragment>
      <Button
        children="Gestisci pagamento"
        onClick={verificationDialogToggle}
        className={classes.button}
        startIcon={<EditIcon />}
        variant="outlined"
        disabled={!allowedToUpdate}
      />
      <AppFormDialog
        open={verificationDialogOpen}
        onClose={verificationDialogToggle}
        title="Modifica stato pagamento"
        onSubmit={onPaymentStatusUpdateSubmit}
        record={{
          payedToSeller: record.payedToSeller,
          verified: record.verified,
          notes: record.notes,
        }}
        width={100}
        height={55}
        canSubmitIfPristine={record.pending}
      >
        <AppSelectInput
          source="verified"
          label="Stato"
          required
          choices={[
            { id: true, name: 'Verificato' },
            { id: false, name: 'Non valido' },
          ]}
        />
        {isPayable && isCdaAdmin ? (
          <AppSelectInput
            source="payedToSeller"
            label="Pagato al venditore"
            required
            choices={[
              { id: true, name: 'Si' },
              { id: false, name: 'No' },
            ]}
          />
        ) : (
          <></>
        )}
        <AppTextInput source="notes" label="Note aggiuntive" md={12} multiline />
      </AppFormDialog>
    </Fragment>
  );
});
